<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <h1>
          <b-badge class="mr-1" variant="primary">宿舍选择</b-badge>
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <el-select
            v-if="!emptyList"
            v-model="roomIndex"
            :popper-append-to-body="false"
            popper-class="selectinfo"
            placeholder="请选择房间"
        >
          <el-option
              v-for="(item, index) in roomsList"
              :key="item.roomId"
              :label="item.block + ' 号楼 ' + item.roomId"
              :value="index"
          >
            <span>{{ item.block }} 号楼 {{ item.roomId }}</span>
          </el-option>
        </el-select>
        <label v-if="emptyList" class="tip-text">{{ emptyTips }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 my-pic-div mb-2 y-panel">
        <div class="l-my-pic"></div>
        <!--        <img class="my-pic" src="media/source/df.png"/>-->
      </div>
    </div>
    <div class="my-panel row" v-if="!emptyList">
      <div
          class="my-bed col-xl-5"
          v-for="(item, index) in bedNameList"
          :key="index"
      >
        <div
            class="text-content"
            v-if="bedInfoList[roomsList[roomIndex].roomId][item] !== null"
        >
          <label class="my-text left-top"
          >序号：{{ numList[index] }} 号床</label
          >
          <label class="my-text right-top">状态：已选</label>
          <label class="my-text"
          >姓名：{{
              bedInfoList[roomsList[roomIndex].roomId][item].name
            }}</label
          >
          <label class="my-text"
          >习惯：{{
              bedInfoList[roomsList[roomIndex].roomId][item].habit
            }}</label
          >
          <label class="my-text"
          >专业：{{
              bedInfoList[roomsList[roomIndex].roomId][item].major
            }}</label
          >
          <label class="my-text"
          >生源：{{
              bedInfoList[roomsList[roomIndex].roomId][item].studentSourceDetail
            }}</label
          >
        </div>
        <b-button
            href="#"
            variant="primary"
            class="my-button"
            v-if="bedInfoList[roomsList[roomIndex].roomId][item] === null"
            @click="chooseRoom(roomsList[roomIndex].roomId, numList[index])"
        >选择 {{ numList[index] }}号床
        </b-button
        >
      </div>
    </div>

    <el-dialog
        title="请补充您的个人信息"
        :visible.sync="dialogVisible"
        width="95%"
        :before-close="handleClose"
    >
      <el-select
          v-model="form.habit"
          :popper-append-to-body="false"
          popper-class="selectinfo"
          placeholder="请选择您的作息习惯"
      >
        <el-option
            v-for="item in habitList"
            :key="item"
            :label="item"
            :value="item"
        >
          <span>{{ item }}</span>
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="chooseConfirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      floorsList: [
        {
          value: "1",
          label: "一楼",
        },
        {
          value: "2",
          label: "二楼",
        },
        {
          value: "3",
          label: "三楼",
        },
        {
          value: "4",
          label: "四楼",
        },
      ],
      roomsList: [
        {
          value: "401",
          roomId: 0,
          block: 1,
        },
        {
          value: "402",
        },
        {
          value: "403",
        },
        {
          value: "404",
        },
      ],
      dialogVisible: false,
      room: "0",
      roomIndex: 0,
      bedNameList: [
        "bedUserInfo1",
        "bedUserInfo2",
        "bedUserInfo4",
        "bedUserInfo3",
      ],
      emptyList: true,
      emptyTips:"提示：当前宿舍没有空余床位，请耐心等待下一批开放",
      bedInfoList: {
        0: {
          bedUserInfo1: "",
          bedUserInfo2: "",
          bedUserInfo3: "",
          bedUserInfo4: "",
        },
      },
      form: {
        habit: "早睡",
        room: "",
        type: "",
        block: "",
      },
      habitList: ["早睡", "常规", "熬夜"],
      numList: [1, 2, 4, 3],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initRoomBedInfo() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/room/getRoomsInfo", //后端请求的路径
        method: "post",
      }).then((response) => {
        if (response.roomsList[0] !== undefined) {
          this.emptyList = false
          this.roomsList = response.roomsList;
          this.room = response.roomsList[0].roomId;
          this.form.block = response.roomsList[0].block;
          this.bedInfoList = response.roomBedInfo;
          let bed = this.bedInfoList[3];
          this.bedInfoList[3] = this.bedInfoList[2];
          this.bedInfoList[2] = bed;
        }
      });
    },
    getData() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/room/getAvailableRooms", //后端请求的路径
        method: "post",
      }).then((response) => {
        if (response.status === 10) {
          this.$message({
            message: "发生错误,请稍后再试！",
            type: "error",
          });
        }
      });
    },
    chooseRoom(room, index, block) {
      this.dialogVisible = true;
      this.form.room = room;
      this.form.block = this.roomsList[this.roomIndex].block;
      this.form.type = index;
    },
    initData() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/user/checkPayStatusByUserId", //后端请求的路径
        method: "post",
      }).then((res) => {
        console.log(res)
        if (res.payStatus != 0) {
          this.$message({
            message: "您还未完成缴费，请先缴费！",
            type: "error",
          });
          this.emptyTips = '您还未完成缴费，请进行缴费或联系老师！！'
        } else {
          this.$axios({
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: sessionStorage.getItem("systemToken"),
            },
            url: "/api/user/checkUserChooseState", //后端请求的路径
            method: "post",
          }).then((response) => {
            if (response.data) {
              this.$message({
                message: "您已经选择过宿舍了！",
                type: "error",
              });
              this.$router.push("/dashboard");
            } else {
              this.initRoomBedInfo();
            }
          });
        }
      });
      // this.$axios({
      //   headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //     Authorization: sessionStorage.getItem("systemToken"),
      //   },
      //   url: "/api/user/checkUserChooseState", //后端请求的路径
      //   method: "post",
      // }).then((response) => {
      //   if (response.data) {
      //     this.$message({
      //       message: "您已经选择过宿舍了！",
      //       type: "error",
      //     });
      //     this.$router.push("/dashboard");
      //   } else {
      //     this.initRoomBedInfo();
      //   }
      // });
    },
    chooseConfirm() {
      this.$confirm("您确定要选择此床位吗？提交以后就不能再次选择了", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.submitChoose();
          })
          .catch(() => {
          });
    },
    submitChoose() {
      this.$axios({
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: sessionStorage.getItem("systemToken"),
        },
        url: "/api/user/chooseBed", //后端请求的路径
        method: "post",
        params: {
          roomId: this.form.room,
          type: this.form.type,
          habit: this.form.habit,
          block: this.form.block,
        },
      }).then((response) => {
        if (response.status === 0) {
          this.$message({
            type: "success",
            message: "选择成功!",
          });
          this.$store.commit("changeChooseState", true);
        } else {
          this.$message({
            type: "error",
            message: "选择失败！原因：" + response.msg,
          });
        }
        this.$router.push("/dashboard");
        this.dialogVisible = false;
      });
    },
    handleClose(done) {
      done();
    },
  },
};
</script>
<style>
.el-select {
  width: 100%;
  margin: 10px 0px 10px 0px;
}

.el-message-box {
  width: 90%;
}

.el-input__inner {
  height: 35px;
}

.el-input--suffix {
  font-size: 15px;
}

.el-select-dropdown__item {
  font-size: 13px;
}

html {
  font-size: 15px;
}

@media (min-width: 1200px) {
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    max-width: 48%;
  }
}
</style>
<style scoped>
.my-pic {
  margin-top: -60px;
  height: 140%;
  width: 100%;
  /*height: auto;*/
  /*max-width: 100%;*/
  /*max-height: 100%;*/
  vertical-align: bottom;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: scale-down;
  display: inline-block;
  text-align: center;
}

.my-pic-div {
  text-align: center;
}

.el-button--primary {
  color: #fff;
}

.my-panel {
  margin: auto;
  width: 100%;
  height: 100%;
  background: #21a7f0;
  border-radius: 8px;
  display: flex;
}

.my-bed {
  height: 13rem;
  background: aliceblue;
  border-radius: 8px;
  display: inline-block;
  margin: 10px 10px 10px 10px;
  overflow: auto;
  text-align: center;
  width: 100%;
}

.my-text {
  text-align: center;
  min-width: 88px;
  width: 45%;
  font-family: "微软雅黑", "黑体", "宋体";
  font-size: 1.25rem;
  font-weight: 500;
}

.my-row {
  width: 100%;
  height: 13rem;
}

.my-button {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  border-radius: 8px;
}

.text-content {
  margin-top: 1.5rem;
  text-align: center;
}

.l-my-pic {
  width: 100%;
  min-width: 700px;
  height: 460px;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/pic/df.png");

}

.y-panel {
  overflow: auto;
}

.tip-text {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  border: 1px solid #b3d8ff;
  background: white;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 10px;
  border-radius: 8px;
}
</style>
